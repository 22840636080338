import React from 'react';
import Layout from '../../components/Layout';
import { CircleCheck } from 'tabler-icons-react';
import { Spoiler, Table, List, ThemeIcon } from '@mantine/core';
import blogd1 from '../../assets/images/2025/anpa-medical-public/1.jpg';
import blogd2 from '../../assets/images/2025/anpa-medical-public/2.jpg';
import blogd3 from '../../assets/images/2025/anpa-medical-public/3.jpg';
import blogd4 from '../../assets/images/2025/anpa-medical-public/4.jpg';
import blogd5 from '../../assets/images/2025/anpa-medical-public/5.jpg';
import blogd6 from '../../assets/images/2025/anpa-medical-public/6.jpg';
import blogd7 from '../../assets/images/2025/anpa-medical-public/7.jpg';
import blogd8 from '../../assets/images/2025/anpa-medical-public/8.jpg';
import blogd9 from '../../assets/images/2025/anpa-medical-public/9.jpg';
import blogd10 from '../../assets/images/2025/anpa-medical-public/10.jpg';
import blogd11 from '../../assets/images/2025/anpa-medical-public/11.jpg';
import blogd12 from '../../assets/images/2025/anpa-medical-public/12.jpg';
import blogd13 from '../../assets/images/2025/anpa-medical-public/13.jpg';
import blogd14 from '../../assets/images/2025/anpa-medical-public/14.jpg';
import blogd15 from '../../assets/images/2025/anpa-medical-public/15.jpg';
import blogd16 from '../../assets/images/2025/anpa-medical-public/16.jpg';
import blogd17 from '../../assets/images/2025/anpa-medical-public/17.jpg';
import blogd18 from '../../assets/images/2025/anpa-medical-public/18.jpg';
import blogd19 from '../../assets/images/2025/anpa-medical-public/19.jpg';
import blogd20 from '../../assets/images/2025/anpa-medical-public/20.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  const services = [
    {
      name: '	Primary and specialized medical consultations',
    },
    {
      name: '  Endoscopic procedures and surgical interventions',
    },
    { name: '	Dental implant workshops ' },
    {
      name:
        '	Health education and public health outreach programs Audit Department ',
    },

    {
      name: '	Community Training – STOP THE BLEED Initiative. ',
    },
  ];
  const services2 = [
    {
      name: '	Wound pressure application',
    },
    {
      name: '  Packing of bleeding wounds and',
    },
    { name: '	Tourniquet application ' },
    // {
    //   name:
    //     '	Health education and public health outreach programs Audit Department ',
    // },

    // {
    //   name: '	Community Training – STOP THE BLEED Initiative. ',
    // },
  ];

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LUTH Hosts Successful ANPA Medical & Public Health Outreach
                  2025
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  {' '}
                  <strong>
                    Lagos University Teaching Hospital (LUTH).
                  </strong>{' '}
                  was honored to serve as the host institution for the
                  Association of Nigerian Physicians in the Americas (ANPA)
                  during their 2025 Medical & Public Health Outreach, held from
                  April 4th to 8th, 2025. The outreach, a major highlight of
                  ANPA’s 30th Anniversary celebrations, brought world-class
                  care, knowledge-sharing, and community empowerment to our
                  grounds.
                  <br />
                  <br />
                  The outreach provided free medical care and specialist
                  services to hundreds of individuals in need, reaffirming
                  LUTH’s commitment to delivering quality healthcare and
                  fostering international collaborations for the benefit of
                  Nigerians.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    <img src={blogd3} height={370} />
                   
                  </div>
                    <p>
                      The Services rendered included:
                      <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {services.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
                      <br />
                      A key moment was the STOP THE BLEED Training held at
                      Marble floor, LUTH on April 5th, where community members
                      were trained on how to effectively respond to traumatic
                      bleeding. The training covered:
                      <br />
                      <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {services2.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
                      <br />
                      We commend ANPA for empowering everyday citizens with such
                      crucial life-saving skills. It was inspiring to see the
                      community so actively engaged in health preparedness.
                    </p>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  LUTH was proud to witness a seamless partnership between our
                  local staff and the visiting ANPA team. The collaborative
                  spirit fostered professional exchange, enhanced procedural
                  expertise, and created a shared commitment to advancing
                  healthcare delivery in Nigeria.
                  <br />
                  We extend our deepest gratitude to ANPA for choosing LUTH as
                  the site for this impactful outreach. We also thank all
                  volunteers, medical professionals, and administrative staff
                  whose efforts ensured the success of the program.
                  <div className='row'>
                    <div className='col-lg-6'>
                      <img src={blogd5} height={370} />
                    </div>
                    <div className='col-lg-6'>
                      <img src={blogd6} height={370} />
                    </div>
                  </div>
                  <br />
                  As we reflect on this remarkable week, LUTH remains committed
                  to advancing healthcare excellence through partnerships,
                  innovation, and compassionate service. We look forward to more
                  future collaborations with ANPA and other global health
                  partners.
                  <div className='row'>
                    <div className='col-lg-12'>
                      <img src={blogd4} height={370} />
                    </div>
                  </div>
                </p>

                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd13} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd14} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd15} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd16} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd17} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd18} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd19} height={370} />
                  </div>
                </div>

                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd1} height={370} width={500} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd20} height={370} width={500} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              <div className='social-block'>
                <a href='#none'>
                  <i className='fab fa-twitter'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-instagram'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-dribbble'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
